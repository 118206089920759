#cliente-header {
    margin-bottom: 16px;
    background-color: #fafafa;
    border: 1px solid #eceff5;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 10px;
}