

.btn-info {
    color: #FFF !important;
}

.main{
background-color: #8f8e8e;

}
.container-body{
margin-top: 15px;
background-color: #FFF;
padding: 15px;
-webkit-border-radius: 8px;
-moz-border-radius: 8px;
border-radius: 8px;
}

.form-check,.form-control {
    margin-bottom: 0.3em !important;
}
.invalid-feedback{
    margin-bottom: 0.3em !important;
}

label{
    font-weight: 500;
}
.mb-10{
    margin-bottom: 0.8em;
}
.mr-15{
    margin-right: 15px;
}
.mr-10{
    margin-right: 10px;
}
.mt-15{
    margin-top: 15px;
}
.bold{
    font-weight: bold;
}